import { TcSmartComponentConfig } from '@tc/abstract';
import { TcSmartButton } from '@tc/buttons';

/**
 *  config interface TcDetailHeader
 */
export interface TcDetailTitleConfig extends TcSmartComponentConfig {
  /**
   * test to display
   * string or translation key
   */
  titlePrefix?: string;

  /**
   *  property name of the form current model to display
   */
  titleProperty?: string;

  /**
   *  configs of the buttons to display
   */
  buttonsList?: TcSmartButton[];

  /**
   * Add a generic save button to the buttonsList
   */
  hasGenericSaveButton?: boolean;

  /**
   * The action that should be dispatched when the generic save button is clicked
   */
  genericSaveButtonAction?: Function;
}
