import { NavigationExtras } from '@angular/router';

export interface TcNavMenuNodes {
  id?: string;
  label: string;
  faIcon?: string;
  icon?: string;
  imageIcon?: string;
  svgIcon?: string;
  activeFaIcon?: string;
  activeIcon?: string;
  activeImageIcon?: string;
  activeSvgIcon?: string;
  hidden?: boolean;
  link?: string;
  externalRedirect?: boolean;
  data?: any;
  items?: TcNavMenuNodes[];
  onSelected?: (args?: any) => void;
  disabled?: boolean;
  expanded?: boolean;
  navigationExtras?: NavigationExtras;
  class?: string;
  selected?: boolean;
  menuItemClass?: string;
  route?: string;
}
