import { FilterTypesEnum } from '@tc/abstract';
import { TcFilterTypes } from '@tc/abstract';
import { TcFilterOptions } from './tc-filter-options';

export interface TcFilter {
  key: string;
  type: TcFilterTypes;
  label?: string;
  hidden?: boolean;
  options?: TcFilterOptions;
  filterType?: FilterTypesEnum;
}
