export interface TcConfirmDialogConfig {
  title?: string;
  /** The message to display. */
  message?: string;
  /** Custom class forthe message. */
  messageClass?: string;
  /** Text to display before yes/no buttons. */
  beforeButtonsText?: string;
  /** Yes button is focused. */
  yesFocused?: boolean;
  /** Has close button. */
  hasCloseButton?: boolean;
  /** Yes button text. */
  yesText?: string;
  /** No button text. */
  noText?: string;
}
