import { TcListFilterModel } from '../../models/list/tc-list-filter.model';
import { TcListPageModel } from '../../models/list/tc-list-page.model';
import { TcListConnectionResult } from '../../models/list/tc-list-connection-result.model';
import { TcListSortModel } from '../../models/list/tc-list-sort.model';

export interface ITcListDataService<T> {
  getData(
    filterModel: TcListFilterModel,
    sortModel: TcListSortModel,
    paginationModel: TcListPageModel
  ): Promise<TcListConnectionResult<T>>;
  getItem(id: string | number): Promise<T>;
}
