import { HttpHeaders, HttpParams } from '@angular/common/http';
import { TcLoggerConfig } from './tc-logger.config';

export interface TcLoggerInterface {
  trace(message: any, ...additional: any[]): void;

  debug(message: any, ...additional: any[]): void;

  info(message: any, ...additional: any[]): void;

  log(message: any, ...additional: any[]): void;

  warn(message: any, ...additional: any[]): void;

  error(message: any, ...additional: any[]): void;

  fatal(message: any, ...additional: any[]): void;

  setCustomHttpHeaders(headers: HttpHeaders): void;

  setCustomParams(params: HttpParams): void;

  updateConfig(config: TcLoggerConfig): void;

  getConfigSnapshot(): TcLoggerConfig;
}
