import { TcSmartListConfig } from '@tc/abstract';

export interface TcPanelListConfig extends TcSmartListConfig {
  /**
   *  component to render items
   */
  component: string;

  /**
   *  itemSize input property defined as the pixel height of each item
   */
  itemSize: number;
}
