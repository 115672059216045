import { TemplateRef } from '@angular/core';

export interface TcGenericListAction {
  actionName: string;
  actionLabel?: string;
  visible: boolean;
  icon?: string;
  hasText?: boolean;
  htmlTemplate?: TemplateRef<any>;
}
