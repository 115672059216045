export * from './components';
export * from './components/tc-detail-header/tc-detail-header.component';
export * from './components/tc-detail-title/tc-detail-title.component';
export * from './components/tc-smart-form/tc-smart-form.component';
export * from './store/actions';
export * from './store';
export * from './store/payloads';
export * from './store/reducers';
export * from './store/selectors';
export * from './store/state';
export * from './tc-smart-form.module';
