import { MaterialTooltipPosition } from '@tc/abstract';

interface TcTooltipConfig {
  text: string;
  class: string;
  position: MaterialTooltipPosition;
  hideDelay: string;
}

interface TcReplaceTexts {
  selectFiles?: string;
  maximumFilesCountWarning?: string;
  fileUploadError?: string;
  fileUploadInvalidExtentionError?: string;
}

export interface TcFileUploaderConfig {
  theme: string;
  multiple?: boolean;
  selectable?: boolean;
  fileDropDisabled?: boolean;
  multipleSelection?: boolean;
  accept?: string;
  maxSize: number;
  maxFileCount?: number;
  tooltip?: TcTooltipConfig;
  replaceTexts?: TcReplaceTexts;
}
