import { TcPdfViewerActionsPosition } from '../enums/tc-pdf-viewer-actions-position.enum';
import { TcPdfViewerUrlSrcConfig } from './tc-pdf-viewer-url-src-config';

export interface TcPdfViewerConfig {
  // Can be a base64 string, a array buffer or a special interface to get the file from a distant URL
  src: string | ArrayBuffer | TcPdfViewerUrlSrcConfig;
  // Filename to be used when downloading PDF file
  filename: string;
  // Display actions buttons on top, bottom or both
  actionsPosition: TcPdfViewerActionsPosition;
  // Set the displayed page if provided. By default page 1 will be displayed.
  page?: number;
}
