import { BaseTcStorePayload } from '@tc/abstract';
import { TcSmartFormConfig } from '@tc/abstract';

/**
 * init tc smart form payload
 */
export interface InitTcSmartFormPayload extends BaseTcStorePayload {
  model?: any;
  config: TcSmartFormConfig;
}

/**
 *  update tc smart form current model
 */
export interface UpdateTcSmartFormCurrentModelPayload
  extends BaseTcStorePayload {
  invalid: boolean;
  currentModel: any;
}

/**
 *  set tc smart form model
 */
export interface SetTcSmartFormModelPayload extends BaseTcStorePayload {
  model: any;
}
