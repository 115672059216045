import { TcForm } from './tc-form.interface';
import { TcGenericEntity } from './tc-generic-entity.interface';

export interface TcGenericForm<T> extends TcForm {
  id: string | number;
  entityName: string;

  submit(model: TcGenericEntity): void;
  afterSave(model?: T): void;
}
