import { TcFilterDef } from '../component-configs/tc-filter.interfaces';
import { TcSortDef } from '../component-configs/tc-sort.interfaces';

export interface ITcDataService<T> {
  readonly name: string;

  getData(
    skip: number,
    take: number,
    filter?: TcFilterDef,
    sort?: TcSortDef
  ): Promise<ITcDataResult<T>>;

  getDataInlineCount(filter?: TcFilterDef): Promise<number>;

  upsert(item: T): Promise<T>;

  delete(item: T): Promise<any>;
}

export interface ITcDataResult<T> {
  data: T[];
  total: number;
}
