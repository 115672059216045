import { AssociationCategorie } from './association-categorie';
import { Logiciel } from './association-logiciel';

export interface Association {
  active: boolean;
  banqueId: string;
  banqueNom: string;
  categorie: AssociationCategorie;
  code: string;
  dateDebutTicadi?: Date;
  decryptedPassword: string;
  equipee: boolean;
  id: number;
  isUsingTicadi: boolean;
  logiciel: Logiciel;
  nom: string;
  numeroVersion: string;
  numeroVersionDisplayed: string;
}