import { TcMultiSelectOptions } from './tc-multi-select-options';

export interface TcFilterOptions extends TcMultiSelectOptions {
  format?: string;
  minLength?: number;
  debounceTime?: number;
  default?: any;
  offLabel?: string;
  onLabel?: string;
  onValue?: any;
  required?: boolean;
  showDropdowns?: boolean;
  hasReset?: boolean;
}
