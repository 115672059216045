import { TcSmartFilterConfig } from './tc-smart-filter-config';
import { TcDataProviderConfig } from './tc-data-provider-config';
import { TcSmartComponentConfig } from './tc-smart-component-config';

/**
 * Configuration of TcDataProvider
 */
export interface TcSmartListConfig extends TcSmartComponentConfig {
  /**
   *  data provider config
   */
  dataProvider: TcDataProviderConfig;

  /**
   *  data provider config for writing data. If not provided dataProvider is used
   */
  writeDataProvider?: TcDataProviderConfig;

  /**
   *  filter configurations
   */
  filterConfig?: TcSmartFilterConfig;

  /**
   *  page size
   */
  pageSize?: number;
}
