export interface TcNavMenuConfiguration {
  classname?: string;
  paddingAtStart?: boolean;
  backgroundColor?: string;
  listBackgroundColor?: string;
  fontColor?: string;
  selectedListFontColor?: string;
  interfaceWithRoute?: boolean;
  collapseOnSelect?: boolean;
  highlightOnSelect?: boolean;
  rtlLayout?: boolean;
}
