export interface Permission {
    action: string;
    subject: string;
}

export interface PermissionsConfig {
    permissions: Permission[];
    roles: string[];
}

export interface UserRights {
    subjects: string[];
    userRoles: { role: string };
    configuration: PermissionsConfig[];
}