import { TcSmartFormConfig } from './tc-smart-form-config';
import { TcSmartListConfig } from './tc-smart-list.config';

/**
 * Configuration to be used by a list with detail component
 */
export interface TcListDetailConfig extends TcSmartListConfig {
  // TODO AAU : Specify more or remove if uneeded : add detail form config
  formConfig: TcSmartFormConfig;
}
