import { TcSmartFilterConfig } from '@tc/abstract';
import { BaseTcStorePayload } from '@tc/abstract';
import { TcFilterDef } from '@tc/abstract';

/**
 * init tc filter payload
 */
export interface InitTcFilterPayload extends BaseTcStorePayload {
  filter?: TcFilterDef;
  isPersistant?: boolean;
  config?: TcSmartFilterConfig;
}

/**
 *  init tc filter success payload
 */
export interface InitTcFilterSuccessPayload extends BaseTcStorePayload {
  filter: TcFilterDef;
  isPersistant?: boolean;
  config?: TcSmartFilterConfig;
}

/**
 *  update tc filters store payload
 */
export interface UpdateTcFilterPayload extends BaseTcStorePayload {
  filter: TcFilterDef;
  alwaysRefresh?: boolean;
}

/**
 *  update tc filters success store payload
 */
export interface UpdateTcFilterSuccessPayload extends BaseTcStorePayload {
  filter: TcFilterDef;
}

/**
 * reset tc filters payload
 */
export interface ResetTcFilterPayload extends BaseTcStorePayload {}
