import { Params } from '@angular/router';
import { BaseCallbackTcStorePayload, BaseTcStorePayload } from '@tc/abstract';

/**
 * edit tc grid button clicked payload
 */
export interface EditTcGridButtonClickedPayload extends BaseTcStorePayload {
  detailsPopupComponent?: any;
  rowData: any;
  width?: string;
  height?: string;
}

/**
 * delete tc grid button clicked payload
 */
export interface DeleteTcGridButtonClickedPayload extends BaseTcStorePayload {
  detailsPopupComponent?: any;
  rowData: any;
  width?: string;
  height?: string;
}

/**
 * deactivate tc grid button clicked payload
 * deactivationField is entity's field that will be changed to deactivate it
 * deactivationValue is the value to deactivate it (for example: false)
 */
export interface DeactivateTcGridButtonClickedPayload
  extends DeleteTcGridButtonClickedPayload {
  deactivationField: string;
  deactivationValue: any;
}

/**
 * add tc grid button clicked payload
 */
export interface AddTcGridButtonClickedPayload extends BaseTcStorePayload {
  detailsPopupComponent?: any;
  defaultModel?: any;
  width?: string;
  height?: string;
}

export interface CloaseAndNavigateButtonClickedPlayload
  extends BaseTcStorePayload {
  route: string;
  queryParams: Params;
}

export interface CloseAndOpenAnotherDetailButtonClickedPlayload
  extends EditTcGridButtonClickedPayload {
  parentGridStoreKey: string;
}

export interface NavigatePayload extends BaseCallbackTcStorePayload {
  route: string;
  queryParams: Params;
  /**
   * Whether or not to refresh the route so that the components get reloaded.
   */
  refreshRoute?: boolean;
}

export interface OpenFilteredDataDetailPayload extends BaseTcStorePayload {
  filterProperty: string;
  filterValue: any;
  detailsPopupComponent: string;
}

export interface OpenEmailAppPayload extends BaseTcStorePayload {
  rowData: any;
  emailField: string;
}

export interface OpenPhoneAppPayload extends BaseTcStorePayload {
  rowData: any;
  phoneField: string;
}
