import { TcConfig } from './tc-config';
import { TcPermissionsConfig } from './tc-permissions-config';

/**
 *  base for all smart components configurations
 */
export interface TcSmartComponentConfig extends TcConfig, TcPermissionsConfig {
  /**
   *  storeKey
   */
  storeKey: string;
}
