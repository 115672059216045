import { TcSmartButton } from '@tc/buttons';
import { TcConfig } from './tc-config';

/**
 *  Configuration for TcDialogHeader, part of the TcDialogContent
 */
export interface TcDialogHeaderConfig extends TcConfig {
  /**
   * Enabled a portal in the title section of the dialog header in which
   * other component can inject a title
   */
  enableTitleSlot?: boolean;

  /**
   * A translation key used for adding a title to the dialog
   * There is also a title slot in which other component can inject via
   * portal a title. So you might not need to specifity this if you have
   * it enabled.
   * Note: if both headerTitle and enableTitleSlot are specified both will
   * appear on the screen first headerTitle then then title slot
   */
  title?: string;

  /**
   * Enabled a portal in the actions section of the dialog header in which
   * other component can inject a buttons
   */
  enableActionsSlot?: boolean;

  /**
   * Enabled a secondary portal in the actions section in case there is a
   * need for one
   */
  enableSecondaryActionsSlot?: boolean;

  /**
   * A list of buttons that will appear in the actions section of the dialog
   * header.
   * Note: if the headerButtonsList and the actionsSlots are specified the
   * headerButtonsList will appear last
   */
  buttonsList?: TcSmartButton[];

  /**
   * Whether or not the dialog should have a close button.
   * If this is set to true a close button will be added to the headerButtonsList
   * The close button action uses the dialogStoreKey as storeKey
   */
  hasCloseButton?: boolean;
}
