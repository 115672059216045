import { FormlyFieldConfig } from '@ngx-formly/core';

export interface TcReportingQuery {
  id: string;
  category?: string;
  parentCategory?: string;
  name: string;
  limit: number;
  filters?: FormlyFieldConfig[];
  display?: boolean;
}
