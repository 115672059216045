import { Type } from '@angular/core';

/**
 * Base payload for all tc panel list payloads. store key is the common property
 */
export interface BaseTcPanelListPayload {
  storeKey: string;
}

/**
 * init tc panel list payload
 */
export interface InitTcPanelListPayload extends BaseTcPanelListPayload {
  component: string;
  itemSize: number;
}
