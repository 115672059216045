import { TcSmartComponentConfig } from '@tc/abstract';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TcDetailTitleConfig } from './tc-detail-title-config';
import { TcDetailHeaderConfig } from './tc-detail-header-config';

/**
 * Configuration for SmartForm component
 */
export interface TcSmartFormConfig extends TcSmartComponentConfig {
  autoSubmit?: boolean;
  titleConfig?: TcDetailTitleConfig;
  headerConfig?: TcDetailHeaderConfig;
  fieldConfigs: FormlyFieldConfig[];
  footerConfig?: TcDetailHeaderConfig;
}
