import { TcConfig } from './tc-config';
import { TcDataProviderType } from './tc-data-services.enum';
import { TcFilterDef } from './tc-filter.interfaces';
import { TcSortDef } from './tc-sort.interfaces';

/**
 * Configuration of TcDataProvider
 */
export interface TcDataProviderConfig extends TcConfig {
  providerType: TcDataProviderType;

  dataSet: string;

  /**
   * A comma-separated (',') string of property paths or an array of property paths
   */
  fields?: string | string[];

  /**
   *  Definition of filters to apply on data set
   */
  filter?: TcFilterDef;

  /*
   * Initial sort
   */
  sortOrder?: TcSortDef;

  /**
   * Distinct/group by capability
   * Gets distinct values of the field specified in the fields property
   */
  distinct?: boolean;

  /**
   * Splits the GET Data call into 2 calls:
   * 1 - for getting the data
   * 2 - for getting the inlineCount
   *
   * Setting this to true drastically improves the performance when working with Mongo views as dataSets
   */
  separatedInlineCountCall?: boolean;

  /**
   * Transforms the result obtained from the execution of the query
   */
  transformFn?: (queryResult: any) => any;
}
