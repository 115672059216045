import { TcConfig } from './tc-config';
import { TcDialogFooterConfig } from './tc-dialog-footer-config';
import { TcDialogHeaderConfig } from './tc-dialog-header-config';
/**
 *  Configuration for TcDialogContent
 */
export interface TcDialogContentConfig extends TcConfig {
  /**
   * Unique dialog identifier. For now we set this to the store key of the form which the dialog
   * contains, which is also the store key of the tc-data/tc-grid/tc-panel-list linked to the form
   * NOTE: If we ever separate the dialog from the tc-grid/tc-panel-list then make sure to set
   *       this to the key of the dialog.
   */
  dialogStoreKey: string;

  /**
   * Whether or not the dialog has a header
   */
  hasHeader?: boolean;

  /**
   * Header config
   */
  headerConfig?: TcDialogHeaderConfig;

  /**
   * Whether or not the dialog has a footer
   */
  hasFooter?: boolean;

  /**
   * Footer config
   */
  footerConfig?: TcDialogFooterConfig;
}
