import { TcConfig } from '@tc/abstract';
import { TcSmartButton } from '@tc/buttons';
import { Observable } from 'rxjs';

/**
 *  config interface TcDetailHeader
 */
export interface TcDetailHeaderConfig extends TcConfig {
  headerText?: string | Observable<string>;
  buttonsList?: TcSmartButton[];
}
