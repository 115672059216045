/**
 * Base payload for tc store payloads. store key is the common property
 */
export interface BaseTcStorePayload {
  storeKey: string;
}

/**
 * Base payload for tc store payloads that also might need to dispatch another action when they are done.
 */
export interface BaseCallbackTcStorePayload extends BaseTcStorePayload {
  callbackAction?: Function;
  callbackActionPayload?: any;
}
