import { TcSmartButton } from '@tc/buttons';
import { TcConfig } from './tc-config';

/**
 *  Configuration for TcDialogFooter, part of the TcDialogContent
 */
export interface TcDialogFooterConfig extends TcConfig {
  /**
   * Enabled a portal in the actions section of the dialog footer in which
   * other component can inject a buttons
   */
  enableActionsSlot?: boolean;

  /**
   * Enabled a secondary portal in the actions section in case there is a
   * need for one
   */
  enableSecondaryActionsSlot?: boolean;

  /**
   * A list of buttons that will appear in the actions section of the dialog
   * footer.
   * Note: if the footerButtonsList and the actionsSlots are specified the
   * footerButtonsList will appear last
   */
  buttonsList?: TcSmartButton[];
}
