export interface TcMultiSelectOptions {
  selectable?: boolean;
  removable?: boolean;
  addOnBlur?: boolean;
  separatorKeysCodes?: number[];
  label?: string;

  default?: any[];
  items?: any[];

  display?: (item: any) => string;
  filter?: (value: string) => any;
}
