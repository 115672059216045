import { TcAppState } from '@tc/store';
import { TcAppConfig } from '../config/tc-app.config';

export interface SettingsState extends TcAppConfig {
  theme: string;
}

export interface State extends TcAppState {
  settings: SettingsState;
}
