import { TcLoggerConfigInterface } from '../logger/tc-logger-config.interface';
import { TcNotificationsConfig } from '../notifications/tc-notifications.config';
import { TcTranslateConfig } from '../translate/tc-translate.config';

export interface TcAppConfig {
  translate: TcTranslateConfig;
  logger: TcLoggerConfigInterface;
  notifications: TcNotificationsConfig;
  theme?: string;
}
