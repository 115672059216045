import { TemplateRef } from '@angular/core';
import { TcListDisplayColumnType } from '../enums/tc-list-display-column-type.enum';

export interface TcGenericListColumn {
  propertyName: string;
  headerLabel?: string;
  visible: boolean;
  filtrable?: boolean;
  htmlTemplate?: TemplateRef<any>;
  sortExpression?: string;
  displayColumnType?: TcListDisplayColumnType;
  htmlDisplayLength?: number;
  dateFormat?: string;
  currencySymbol?: string;
}
