import { FormlyFieldConfig } from '@ngx-formly/core';
import { TcSmartComponentConfig } from './tc-smart-component-config';

/*
 *  Configuration for TcSmartFilter
 */
export interface TcSmartFilterConfig extends TcSmartComponentConfig {
  /*
   *  formly fields definitions
   */
  fields: FormlyFieldConfig[];

  /*
   *   flag to determine if filters are persistant or not
   */
  isPersistant?: boolean;

  /**
   *  list of fields to be used in any field contains query
   */
  anyFieldContainsFields?: string[];

  /**
   *  list of fields to be used in any field starts with query
   */
  anyFieldStartsWithFields?: string[];

  /**
   *  list of fields to be used in any field equals query
   */
  anyFieldEqualsFields?: string[];
}
