export interface OfflineModeSyncResponse {
  success: boolean; // Define if the sync was successful or not
  startingDate: Date; // Datetime of start of the sync
  endingDate: Date; // Datetime of end of the sync
  collections?: {
    name: string;
    number: number;
  }[]; // Collections synced and number of item inside
  errors?: any[]; // Any error than occured in the process
}

export interface SyncResponsePopupPayload extends OfflineModeSyncResponse {
  title: string;
}
